'use client'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Field, Form, InputField } from '@kheruc/ui'
import { signIn } from './actions'
import { signInSchema } from '@kheruc/platformcms/shared'
import type { SignInFormInputs } from '@kheruc/platformcms/shared'

export function SignInForm() {
  const [error, setError] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<SignInFormInputs>({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onSubmit = async (form: SignInFormInputs) => {
    setError(null)

    try {
      await signIn(form)
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message)
      }
      setError('Error signing in, please try again.')
    }
  }

  return (
    <Form error={error} isSubmitting={isSubmitting} button="Sign In" onSubmit={handleSubmit(onSubmit)}>
      <Field.Group>
        <InputField
          name="email"
          type="email"
          label="Email"
          disabled={isSubmitting}
          errors={errors}
          register={register}
        />
        <InputField
          name="password"
          type="password"
          label="Password"
          disabled={isSubmitting}
          errors={errors}
          register={register}
        />
      </Field.Group>
    </Form>
  )
}
